import {useEffect, useCallback, useState} from "react";

import {toast} from 'react-toastify';

import {useAccount, useContractRead, erc20ABI, useContractWrite, useSwitchNetwork, useChainId} from "wagmi";
import {waitForTransaction} from '@wagmi/core'
import {BUY_ADDR, BUY_CHAIN_ID} from '../../config/config'

import buyAbi from "../../assets/abi/Buy.json"
import {useTranslation} from "react-i18next";
import * as React from "react";
import {parseUnits, zeroAddress} from "viem";
import BigNumber from "bignumber.js";
import ConsoleNode from "./components/ConsoleNode";
import Spinner from "@rsuite/icons/legacy/Spinner";
import ConsoleActive from "./components/ConsoleActive";

export default function Console() {
	const {t} = useTranslation()
	const account = useAccount()
	const [showUsdtPrice, setShowUsdtPrice] = useState(1)
	const [usdtPrice, setUsdtPrice] = useState(1)
	const [usdtReceiveAddress, setUsdtReceiveAddress] = useState(zeroAddress)
	const [levelOneReward, setLevelOneReward] = useState(0)
	const [levelTwoReward, setLevelTwoReward] = useState(0)
	const [loading, setLoading] = useState(false)
	const {switchNetworkAsync} = useSwitchNetwork()
	const chainId = useChainId()

	// 节点价格
	const {refetch: refetchPrice} = useContractRead({
		address: BUY_ADDR,
		abi: buyAbi,
		functionName: 'price',
		args: [],
		watch: true,
	})

	// 设置节点价格
	const {writeAsync: editPrice} = useContractWrite({
		address: BUY_ADDR,
		abi: buyAbi,
		functionName: 'editPrice',
		args: [usdtPrice]
	})

	// USDT接收地址
	const {refetch: refetchUsdtReceiveAddr} = useContractRead({
		address: BUY_ADDR,
		abi: buyAbi,
		functionName: 'usdtReceiveAddr',
		args: [],
		watch: true,
	})

	// 设置USDT接收地址
	const {writeAsync: editUsdtReceiveAddr} = useContractWrite({
		address: BUY_ADDR,
		abi: buyAbi,
		functionName: 'editUsdtReceiveAddr',
		args: [usdtReceiveAddress]
	})

	// 一级推荐人返利
	const {refetch: refetchLevelOneReward} = useContractRead({
		address: BUY_ADDR,
		abi: buyAbi,
		functionName: 'levelOneReward',
		args: [],
		watch: true,
	})

	// 设置一级推荐人返利
	const {writeAsync: editLevelOneReward} = useContractWrite({
		address: BUY_ADDR,
		abi: buyAbi,
		functionName: 'editLevelOneReward',
		args: [levelOneReward]
	})

	// 二级推荐人返利
	const {refetch: refetchLevelTwoReward} = useContractRead({
		address: BUY_ADDR,
		abi: buyAbi,
		functionName: 'levelTwoReward',
		args: [],
		watch: true,
	})

	// 设置二级推荐人返利
	const {writeAsync: editLevelTwoReward} = useContractWrite({
		address: BUY_ADDR,
		abi: buyAbi,
		functionName: 'editLevelTwoReward',
		args: [levelTwoReward]
	})

	const [agent, setAgent] = useState("")
	// 添加代购
	const {writeAsync: addAgent} = useContractWrite({
		address: BUY_ADDR,
		abi: buyAbi,
		functionName: 'addAgent',
		args: [agent]
	})

	// 删除代购
	const {writeAsync: delAgent} = useContractWrite({
		address: BUY_ADDR,
		abi: buyAbi,
		functionName: 'delAgent',
		args: [agent]
	})


	const dec = new BigNumber(1000000000000000000)
	useEffect(() => {
		refetchPrice().then(({data: price}) => {
			let bigPrice = new BigNumber(price).dividedBy(dec)
			setShowUsdtPrice(bigPrice.toString())
			setUsdtPrice(price)
		})
		refetchUsdtReceiveAddr().then(({data: address}) => {
			setUsdtReceiveAddress(address)
		})
		refetchLevelOneReward().then(({data: reward}) => {
			setLevelOneReward(parseInt(reward))
		})
		refetchLevelTwoReward().then(({data: reward}) => {
			setLevelTwoReward(parseInt(reward))
		})
	}, [])

	const changePrice = (e) => {
		const price = e.target.value
		setShowUsdtPrice(price)
		// const bigPrice = (new BigNumber(price)).times(dec)
		setUsdtPrice(parseUnits(price, 18))
	}

	// 设置USDT接收地址
	const [usdtReceiveAddrLoading, setUsdtReceiveAddrLoading] = useState(false)
	const handleSetUsdtReceiveAddr = useCallback(async () => {
		if (chainId != BUY_CHAIN_ID) {
			await switchNetworkAsync(BUY_CHAIN_ID)
		}
		if (account.isConnected) {
			setUsdtReceiveAddrLoading(true)
			try {
				const txHash = await editUsdtReceiveAddr()
				const tx = await waitForTransaction({...txHash, confirmations: 3})
				toast(t("Success"))
			} catch (e) {
				toast(t("Fail"))
			} finally {
				setUsdtReceiveAddrLoading(false)
			}
		} else {
			toast(t("Please connect wallet first"))
		}
	}, [editUsdtReceiveAddr])

	// 设置价格
	const [priceLoading, setPriceLoading] = useState(false)
	const handleSetPrice = useCallback(async () => {
		if (chainId != BUY_CHAIN_ID) {
			await switchNetworkAsync(BUY_CHAIN_ID)
		}
		if (account.isConnected) {
			setPriceLoading(true)
			try {
				const txHash = await editPrice()
				const tx = await waitForTransaction({...txHash, confirmations: 3})
				toast(t("SUCCESS"))
				console.log("交易ID", tx)
			} catch (e) {
				toast(t("Fail"))
			} finally {
				setPriceLoading(false)
			}
		} else {
			toast(t("Please connect wallet first"))
		}
	}, [editPrice])

	// 设置一级推荐人返利
	const [levelOneRewardLoading, setLevelOneRewardLoading] = useState(false)
	const handleSetLevelOneReward = useCallback(async () => {
		if (chainId != BUY_CHAIN_ID) {
			await switchNetworkAsync(BUY_CHAIN_ID)
		}
		if (account.isConnected) {
			setLevelOneRewardLoading(true)
			try {
				const txHash = await editLevelOneReward()
				const tx = await waitForTransaction({...txHash, confirmations: 3})
				toast(t("SUCCESS"))
				console.log("交易ID", tx)
			} catch (e) {
				toast(t("Fail"))
			} finally {
				setLevelOneRewardLoading(false)
			}
		} else {
			toast(t("Please connect wallet first"))
		}
	}, [editLevelOneReward])

	// 设置二级推荐人返利
	const [levelTwoRewardLoading, setLevelTwoRewardLoading] = useState(false)
	const handleSetLevelTwoReward = useCallback(async () => {
		if (chainId != BUY_CHAIN_ID) {
			await switchNetworkAsync(BUY_CHAIN_ID)
		}
		if (account.isConnected) {
			setLevelTwoRewardLoading(true)
			try {
				const txHash = await editLevelTwoReward()
				const tx = await waitForTransaction({...txHash, confirmations: 3})
				toast(t("SUCCESS"))
				console.log("交易ID", tx)
			} catch (e) {
				toast(t("Fail"))
			} finally {
				setLevelTwoRewardLoading(false)
			}
		} else {
			toast(t("Please connect wallet first"))
		}
	}, [editLevelTwoReward])


	// 添加代购
	const [addAgentLoading, setAddAgentLoading] = useState(false)
	const handleAddAgent = useCallback(async () => {
		if (chainId != BUY_CHAIN_ID) {
			await switchNetworkAsync(BUY_CHAIN_ID)
		}
		if (account.isConnected) {
			setAddAgentLoading(true)
			try {
				const txHash = await addAgent()
				const tx = await waitForTransaction({...txHash, confirmations: 3})
				toast(t("SUCCESS"))
				console.log("交易ID", tx)
			} catch (e) {
				toast(t("Fail"))
			} finally {
				setAddAgentLoading(false)
			}
		} else {
			toast(t("Please connect wallet first"))
		}
	}, [addAgent])

	// 删除代购
	const [delAgentLoading, setDelAgentLoading] = useState(false)
	const handleDelAgent = useCallback(async () => {
		if (chainId != BUY_CHAIN_ID) {
			await switchNetworkAsync(BUY_CHAIN_ID)
		}
		if (account.isConnected) {
			setDelAgentLoading(true)
			try {
				const txHash = await delAgent()
				const tx = await waitForTransaction({...txHash, confirmations: 3})
				toast(t("SUCCESS"))
				console.log("交易ID", tx)
			} catch (e) {
				toast(t("Fail"))
			} finally {
				setDelAgentLoading(false)
			}
		} else {
			toast(t("Please connect wallet first"))
		}
	}, [delAgent])


	return (
		<div className="relative py-24 sm:py-32">
			<div className="px-6 mx-auto text-white max-w-7xl lg:px-8">
				<div className="flex flex-col items-center justify-center mx-auto sm:text-center">
					<div
						className="bg-gradient-to-r mt-12 rounded-xl from-[#0D1732] to-[#0C2530] w-full overflow-auto text-white">
						<div className="_md:w-[1225px]">
							<div className="w-full flex items-center p-[10px]">
								<div className="mr-[20px]">节点价格：</div>
								<div className="flex-1">
									<input type="text" value={showUsdtPrice}
										   onChange={(e) => changePrice(e)}
										   className="w-full p-2 bg-transparent border border-white rounded-xl"></input>
								</div>
								<div className="ml-[20px]">
									<div
										className="bg-gradient-to-r from-[#1A61ED] to-[#11BAE3] py-3 px-5 rounded-xl cursor-pointer text-center"
										onClick={handleSetPrice}>{priceLoading ? <Spinner pulse/> : <> </>}保存
									</div>
								</div>
							</div>
							<div className="w-full flex items-center p-[10px] mt-[10px]">
								<div className="mr-[20px]">USDT接收地址：</div>
								<div className="flex-1">
									<input type="text" value={usdtReceiveAddress}
										   onChange={(e) => setUsdtReceiveAddress(e.target.value)}
										   className="w-full p-2 bg-transparent border border-white rounded-xl"></input>
								</div>
								<div className="ml-[20px]">
									<div
										className="bg-gradient-to-r from-[#1A61ED] to-[#11BAE3] py-3 px-5 rounded-xl cursor-pointer text-center"
										onClick={handleSetUsdtReceiveAddr}>{usdtReceiveAddrLoading ?
										<Spinner pulse/> : <> </>}保存
									</div>
								</div>
							</div>
							<div className="w-full flex items-center p-[10px]">
								<div className="mr-[20px]">一级推荐人返利(百分比)：</div>
								<div className="flex-1">
									<input type="text" value={levelOneReward}
										   onChange={(e) => {
											   setLevelOneReward(e.target.value)
										   }}
										   className="w-full p-2 bg-transparent border border-white rounded-xl"></input>
								</div>
								<div className="ml-[20px]">
									<div
										className="bg-gradient-to-r from-[#1A61ED] to-[#11BAE3] py-3 px-5 rounded-xl cursor-pointer text-center"
										onClick={handleSetLevelOneReward}>{levelOneRewardLoading ?
										<Spinner pulse/> : <> </>}保存
									</div>
								</div>
							</div>
							<div className="w-full flex items-center p-[10px]">
								<div className="mr-[20px]">二级推荐人返利(百分比)：</div>
								<div className="flex-1">
									<input type="text" value={levelTwoReward}
										   onChange={(e) => {
											   setLevelTwoReward(e.target.value)
										   }}
										   className="w-full p-2 bg-transparent border border-white rounded-xl"></input>
								</div>
								<div className="ml-[20px]">
									<div
										className="bg-gradient-to-r from-[#1A61ED] to-[#11BAE3] py-3 px-5 rounded-xl cursor-pointer text-center"
										onClick={handleSetLevelTwoReward}>{levelTwoRewardLoading ?
										<Spinner pulse/> : <> </>}保存
									</div>
								</div>
							</div>
							{/*<div className="w-full flex items-center p-[10px]">*/}
							{/*	<div className="mr-[20px]">代购人地址：</div>*/}
							{/*	<div className="flex-1">*/}
							{/*		<input type="text"*/}
							{/*			   onChange={(e) => {*/}
							{/*				   setAgent(e.target.value)*/}
							{/*			   }}*/}
							{/*			   className="w-full p-2 bg-transparent border border-white rounded-xl"></input>*/}
							{/*	</div>*/}
							{/*	<div className="ml-[20px]">*/}
							{/*		<div*/}
							{/*			className="bg-gradient-to-r from-[#1A61ED] to-[#11BAE3] py-3 px-5 rounded-xl cursor-pointer text-center"*/}
							{/*			onClick={handleAddAgent}>{addAgentLoading ?*/}
							{/*			<Spinner pulse/> : <> </>}添加*/}
							{/*		</div>*/}
							{/*	</div>*/}
							{/*	<div className="ml-[10px]">*/}
							{/*		<div*/}
							{/*			className="bg-gradient-to-r from-[#1A61ED] to-[#11BAE3] py-3 px-5 rounded-xl cursor-pointer text-center"*/}
							{/*			onClick={handleDelAgent}>{delAgentLoading ?*/}
							{/*			<Spinner pulse/> : <> </>}删除*/}
							{/*		</div>*/}
							{/*	</div>*/}
							{/*</div>*/}
							<ConsoleNode/>
							<ConsoleActive/>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
