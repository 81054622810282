import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// import zhCN from './i18n/zh_cn.json'
import zhTW from './i18n/zh_tw.json'
import jp from './i18n/jp.json'
import ko from './i18n/ko.json'

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {}
  },
  // zh_cn: {
  //   translation: zhCN
  // },
  zh_tw: {
    translation: zhTW
  },
  jp: {
    translation: jp
  },
  ko: {
    translation: ko
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage.getItem("__i18n__") || 'en', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    fallbackLng: 'en',

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;