import '../../css/project-base.css';
import {Fragment, useEffect, useMemo, useState} from 'react'
import {Disclosure, Menu, Transition} from '@headlessui/react'
import {Bars3Icon, BellIcon, XMarkIcon} from '@heroicons/react/24/outline'

import {ConnectButton} from "@rainbow-me/rainbowkit";
import {useAccount, useContractRead, usePublicClient} from 'wagmi'
import {useTranslation} from "react-i18next";
import {BUY_ADDR, BUY_CHAIN_ID, NODE_ADDR} from "../../config/config";
import buyAbi from "../../assets/abi/Buy.json";
import {useNavigate, useLocation} from "react-router-dom";
import axios from "axios";
import {ActiveBASE} from "../../utils/http";
import nodeAbi from "../../assets/abi/Node.json";

function classNames(...classes) {
	return classes.filter(Boolean).join(' ')
}

const languages = [
	{key: 'en', label: 'English'},
	// {key: 'zh_cn', label: '简体中文'},
	{key: 'zh_tw', label: '繁體中文'},
	{key: 'jp', label: '日本語'},
	{key: 'ko', label: '한국인'},
]

export default function Navbar() {
	const {t, i18n} = useTranslation()

	const language = useMemo(() => {
		let defaultLanguage = i18n.language
		if (defaultLanguage == "zh_cn") {
			defaultLanguage = "en"
		}
		// i18n.language = defaultLanguage
		// return languages.find(e => e.key === i18n.language)
		return languages.find(e => e.key === defaultLanguage)
	}, [i18n.language])

	const params = new URLSearchParams(window.location.search);
	const inviter = params.get('inviter');
	if (inviter) {
		sessionStorage.setItem("inviter", inviter);
	}

	const [navigation, setNavigation] = useState([
		{name: t`HOME`, href: '/', current: true},
		{name: t`NODES`, href: '/nodes', current: false},
		{name: t`MYNODE`, href: '/mynode', current: false},
		{name: t`DOCS`, href: 'https://datadao.gitbook.io/datadao/', current: false},
		{name: t`POLICY`, href: '/policy', current: false},
		{name: t`MISSION`, href: '/mission', current: false},
	])

	const navigate = useNavigate()
	const location = useLocation()
	const account = useAccount()
	const {isConnected, address} = useAccount()

	// 管理员
	const {data: manager} = useContractRead({
		address: BUY_ADDR,
		abi: buyAbi,
		functionName: 'manager',
		args: [],
		watch: true,
		chainId:BUY_CHAIN_ID
	})


	// // 是否是代购
	// const {data: isAgent} = useContractRead({
	// 	address: BUY_ADDR,
	// 	abi: buyAbi,
	// 	functionName: 'isAgent',
	// 	args: [address],
	// 	watch: true,
	// 	chainId:BUY_CHAIN_ID
	// })


	useEffect(() => {
		if (location.pathname != "/console"&&location.pathname != "/purchase") {
			if (!account.isConnected) {
				navigate("/login")
			} else if (location.pathname != "/login") {
				axios.request(ActiveBASE + '/user/inviter', {
					method: 'POST',
					data: {address: account.address}
				}).then((res) => {
					if (res.status != 200 || res.data.code != 0 || res.data.data.inviter == "") {
						navigate("/login")
					}
				})
			}
		}
	}, [isConnected, address, location])

	useEffect(() => {
		if (isConnected && address == manager) {
			let existed = false
			navigation.forEach((val) => {
				if (val.href == '/console') {
					existed = true
				}
			})
			if (!existed) {
				navigation.push({name: "管理面板", href: '/console', current: false})
				setNavigation(navigation)
			}
		}

		if (!isConnected || address != manager) {
			navigation.forEach((val, index) => {
				if (val.href == '/console') {
					navigation.splice(index, 1)
				}
			})
			setNavigation(navigation)
		}
	}, [address, isConnected, manager]);


	return (
		<Disclosure as="nav" className="relative">
			{({open}) => (
				<>
					<div className="px-2 mx-auto max-w-7xl sm:px-6 lg:px-8">
						<div className="relative flex items-center justify-between h-16">
							<div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
								{/* Mobile menu button*/}
								<Disclosure.Button
									className="relative inline-flex items-center justify-center p-2 text-gray-400 rounded-md hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
									<span className="absolute -inset-0.5"/>
									<span className="sr-only">Open main menu</span>
									{open ? (
										<XMarkIcon className="block w-6 h-6" aria-hidden="true"/>
									) : (
										<Bars3Icon className="block w-6 h-6" aria-hidden="true"/>
									)}
								</Disclosure.Button>
							</div>
							<div
								className="flex items-center justify-center flex-1 sm:items-stretch sm:justify-between">
								<div
									className="text-[#1587E7] items-center flex-shrink-0 font-semibold text-xl sm:flex gap-2 hidden">
									<span>DataDAO</span>

									<select
										className={'bg-transparent text-white text-xs'}
										value={language.key}
										onChange={(e) => {
											i18n.changeLanguage(e.target.value)
											localStorage.setItem("__i18n__", e.target.value)
										}}
									>
										{languages.map(e => <option key={e.key} value={e.key}>{e.label}</option>)}
									</select>
								</div>
								<div className="hidden sm:ml-6 sm:block">
									<div className="flex space-x-4">
										{navigation.map((item) => (
											<a
												key={item.name}
												href={item.href}
												className={classNames(
													'rounded-md px-3 py-2 text-sm text-white hover:bg-white hover:bg-opacity-20'
												)}
												aria-current={item.current ? 'page' : undefined}
											>
												{item.name}
											</a>
										))}
									</div>
								</div>

								<div class="flex">
									<ConnectButton.Custom>
										{({
											  account,
											  chain,
											  openAccountModal,
											  openChainModal,
											  openConnectModal,
											  authenticationStatus,
											  mounted,
										  }) => {
											const ready = mounted && authenticationStatus !== 'loading';
											const connected =
												ready &&
												account &&
												chain &&
												(!authenticationStatus ||
													authenticationStatus === 'authenticated');

											return (
												<div
													{...(!ready && {
														'aria-hidden': true,
														'style': {
															opacity: 0,
															pointerEvents: 'none',
															userSelect: 'none',
														},
													})}
												>
													{(() => {
														if (!connected) {
															return (
																<button
																	className="gradient-border text-sm py-1 px-3 rounded-md flex items-center text-transparent bg-gradient-to-r from-[#1A61ED] to-[#11BAE3] bg-clip-text"
																	onClick={openConnectModal} type="button">
																	Connect Wallet
																</button>
															);
														}
														return (
															<div
																className='flex gap-5 p-2 text-white border border-white rounded-md'>
																<button
																	onClick={openChainModal}
																	style={{display: 'flex', alignItems: 'center'}}
																	type="button"
																>
																	{chain.hasIcon && (
																		<div
																			style={{
																				background: chain.iconBackground,
																				width: 24,
																				height: 24,
																				borderRadius: 999,
																				overflow: 'hidden',
																				marginRight: 4,
																			}}
																		>
																			{chain.iconUrl && (
																				<img
																					alt={chain.name ?? 'Chain icon'}
																					src={chain.iconUrl}
																					style={{width: 24, height: 24}}
																				/>
																			)}
																		</div>
																	)}
																	{chain.name}
																</button>

																<button onClick={openAccountModal} type="button">
																	{account.displayName}
																	{account.displayBalance
																		? ` (${account.displayBalance})`
																		: ''}
																</button>
															</div>
														);
													})()}
												</div>
											);
										}}
									</ConnectButton.Custom>
									<a href="https://v1web.datadao.net"
									   className="gradient-border text-sm py-1 px-3 rounded-md flex items-center text-transparent bg-gradient-to-r from-[#1A61ED] to-[#11BAE3] bg-clip-text ml-4"
									   type="button">
										V1
									</a>
									<a href="https://v3.datadao.net"
									   className="gradient-border text-sm py-1 px-3 rounded-md flex items-center text-transparent bg-gradient-to-r from-[#1A61ED] to-[#11BAE3] bg-clip-text ml-4"
									   type="button">
										V3
									</a>
								</div>
							</div>
						</div>
					</div>

					<Disclosure.Panel className="sm:hidden">
						<div className="px-2 pt-2 pb-3 space-y-1">
							{navigation.map((item) => (
								<Disclosure.Button
									key={item.name}
									as="a"
									href={item.href}
									className={classNames(
										'block rounded-md px-3 py-2 text-base font-medium text-white hover:bg-white hover:bg-opacity-20'
									)}
									aria-current={item.current ? 'page' : undefined}
								>
									{item.name}
								</Disclosure.Button>
							))}

							<select
								className={'bg-transparent text-white'}
								value={language.key}
								onChange={(e) => {
									i18n.changeLanguage(e.target.value)
									localStorage.setItem("__i18n__", e.target.value)
								}}
							>
								{languages.map(e => <option key={e.key} value={e.key}>{e.label}</option>)}
							</select>
						</div>
					</Disclosure.Panel>
				</>
			)}
		</Disclosure>
	)
}
