import * as React from 'react'
import {useState, useEffect, useCallback} from "react";

import {XMarkIcon} from '@heroicons/react/24/outline'
import '../../css/project-base.css';


import {waitForTransaction, prepareWriteContract, writeContract} from '@wagmi/core'


import {toast} from 'react-toastify';

import copy from 'copy-to-clipboard';
import {useTranslation} from "react-i18next";
import {
	erc20ABI,
	useAccount,
	useChainId,
	useConfig,
	useContractRead,
	useContractWrite,
	usePublicClient,
	useSwitchNetwork
} from "wagmi";
import {
	DATAD_ADDR,
	MEMO_ADDR,
	BUY_ADDR,
	USDT_ADDR,
	NODE_ADDR,
	ACTIVE_ADDR,
	NODE_CHAIN_ID,
	BUY_CHAIN_ID, ACTIVE_MEMO_ADDR
} from "../../config/config";
import buyAbi from "../../assets/abi/Buy.json";
import nodeAbi from "../../assets/abi/Node.json";
import activeAbi from "../../assets/abi/Active.json"
import dayjs from "dayjs";
import BigNumber from "bignumber.js";
import {formatUnits, parseUnits} from "viem";
import axios from "axios";
import {ActiveBASE, BASE} from "../../utils/http";
import {maxUint256} from "viem";
import Spinner from "@rsuite/icons/legacy/Spinner";

function useLocalState(initialValue) {
	const [state, setState] = useState(initialValue);
	const setLocalState = (value) => setState(value);
	return [state, setLocalState];
}

export default function Intro() {
	const {t} = useTranslation()

	const [showModal, setShowModal] = useState(0);
	const [selectedOption, setSelectedOption] = useState("USDT")
	const [withdrawToken, setWithdrawToken] = useState(MEMO_ADDR)

	const [withdrawNum, setWithdrawNum] = useLocalState(0);
	const [bigWithdrawNum, setBigWithdrawNum] = useLocalState(0);
	const [rechargeNum, setRechargeNum] = useLocalState(0);
	const [usdt, setUsdt] = useState(0);
	const [memo, setMemo] = useState(0);
	const [datad, setDatad] = useState(0);
	const [nodes, setNodes] = useState([])
	const [activeNumber, setActiveNumber] = useState()
	const [loading, setLoading] = useState(false)
	const [showActiveConfirm, setShowActiveConfirm] = useState(0)

	const {address, isConnected} = useAccount()
	const chainId = useChainId()

	const nodeClient = usePublicClient({chainId: NODE_CHAIN_ID})
	const buyClient = usePublicClient({chainId: BUY_CHAIN_ID})
	const {switchNetworkAsync} = useSwitchNetwork()
	const [childrenCount, setChildrenCount] = useState(0)

	const {refetch: refetchBalance} = useContractRead({
		address: BUY_ADDR,
		abi: buyAbi,
		functionName: 'balance',
		args: [address],
		watch: false,
		chainId: BUY_CHAIN_ID
	})

	// 列表
	const {refetch: refetchNodes} = useContractRead({
		address: BUY_ADDR,
		abi: buyAbi,
		functionName: 'userNodes',
		args: [address],
		watch: false,
		chainId: BUY_CHAIN_ID
	})

	// 提现
	const {writeAsync: withdraw} = useContractWrite({
		address: BUY_ADDR,
		abi: buyAbi,
		functionName: 'withdraw',
		args: [withdrawToken, bigWithdrawNum],
		chainId: BUY_CHAIN_ID
	})


	// 节点余额
	const {refetch: refetchNodeBalance} = useContractRead({
		address: NODE_ADDR,
		abi: nodeAbi,
		functionName: 'balance',
		args: [address],
		watch: false,
		chainId: NODE_CHAIN_ID
	})

	// 节点提现
	const {writeAsync: nodeWithdraw} = useContractWrite({
		address: NODE_ADDR,
		abi: nodeAbi,
		functionName: 'withdraw',
		args: [withdrawToken, bigWithdrawNum],
		chainId: NODE_CHAIN_ID
	})

	// 激活授权
	const {data: activeAllowance} = useContractRead({
		address: ACTIVE_MEMO_ADDR,
		abi: erc20ABI,
		functionName: 'allowance',
		args: [address, ACTIVE_ADDR],
		chainId: BUY_CHAIN_ID,
		watch: true,
	})


	// 激活价格
	const {data: activeAmount} = useContractRead({
		address: ACTIVE_ADDR,
		abi: activeAbi,
		functionName: 'activeMemoAmount',
		args: [],
		chainId: BUY_CHAIN_ID,
		watch: false,
	})

	// 节点激活授权
	const {writeAsync: activeApprove} = useContractWrite({
		address: ACTIVE_MEMO_ADDR,
		abi: erc20ABI,
		functionName: 'approve',
		args: [ACTIVE_ADDR, maxUint256],
		chainId: BUY_CHAIN_ID
	})


	// 节点详情
	const nodeAddDetail = async (number, client) => {
		try {
			return await client.readContract({
				abi: nodeAbi,
				address: NODE_ADDR,
				functionName: "nodeDetail",
				args: [number],
			})
		} catch (e) {
			console.log("错误", e.toString())
		}
	}

	// 节点激活详情
	const nodeActiveDetail = async (number, client) => {
		try {
			return await client.readContract({
				abi: activeAbi,
				address: ACTIVE_ADDR,
				functionName: "detail",
				args: [number],
			})
		} catch (e) {
			console.log(e)
		}
	}

	// 节点详情
	const refreshNodeDetail = async (nodeClient, buyClient) => {
		const {data} = await refetchNodes()
		let nodes = []
		for (let i = 0; i < data.length; i++) {
			// 初始化详情
			let detail = {
				number: parseInt(data[i]),
				activeTime: 0,
				expiredTime: 0,
				datad: 0,
				memo: 0,
				activated: false
			}
			// 查询是否激活
			const activeDetail = await nodeActiveDetail(detail.number, buyClient)
			if (parseInt(activeDetail) > 0) {
				detail.activated = true
			}
			// 查询是否添加
			const addDetail = await nodeAddDetail(detail.number, nodeClient)
			detail.activeTime = parseInt(addDetail.activeTime)
			detail.expiredTime = parseInt(addDetail.expiredTime)
			detail.datad = formatUnits(addDetail.settleDatad, 18)
			detail.memo = formatUnits(addDetail.settleMemo, 18)
			nodes.push(detail)
		}
		setNodes(nodes)
	}

	useEffect(() => {
		if (isConnected) {
			refetchBalance().then(({data: usdt}) => {
				let bigUsdt = formatUnits(usdt, 18)
				setUsdt(bigUsdt.toString())
			})
			refetchNodeBalance().then(({data: [memo, datad]}) => {
				console.log("余额",memo,datad)
				setMemo(formatUnits(memo, 18))
				setDatad(formatUnits(datad, 18))
			})
			refreshNodeDetail(nodeClient, buyClient)
		}
	}, [isConnected, nodeClient, buyClient])

	useEffect(() => {
		if (isConnected) {
			axios.request(ActiveBASE + '//user/children-count', {
				method: 'POST',
				data: {address: address}
			}).then((res) => {
				if (res.status == 200 && res.data.code == 0) {
					setChildrenCount(res.data.data.count)
				}
			})
		}
	}, [isConnected])


	useEffect(() => {
		if (selectedOption == "MEMO") {
			setWithdrawToken(MEMO_ADDR)
		}
		if (selectedOption == "DATAD") {
			setWithdrawToken(DATAD_ADDR)
		}
		if (selectedOption == "USDT") {
			setWithdrawToken(USDT_ADDR)
		}

	}, [selectedOption])

	const handleSetBigWithdrawNum = (val) => {
		setWithdrawNum(val)
		setBigWithdrawNum(parseUnits(val, 18))
	}


	const handleWithdraw = useCallback(async () => {
		if (isConnected) {
			if (nodes.length == 0) {
				return toast("Must bought a node!")
			}
			setLoading(true)
			try {
				let txHash;
				if (selectedOption == "USDT") {
					if (chainId != BUY_CHAIN_ID) {
						await switchNetworkAsync(BUY_CHAIN_ID)
					}
					txHash = await withdraw()
				} else {
					if (chainId != NODE_CHAIN_ID) {
						await switchNetworkAsync(NODE_CHAIN_ID)
					}
					txHash = await nodeWithdraw()
				}
				const tx = await waitForTransaction({...txHash, confirmations: 3, timeout: 600000})
				toast(t("SUCCESS"))
				console.log("交易ID", tx)
			} catch (e) {
				let type = 5
				if (selectedOption == "MEMO") {
					type = 6
				} else if (selectedOption == "DATAD") {
					type = 7
				}
				try {
					await axios.request(ActiveBASE + '/fail-reason/add', {
						method: 'POST',
						data: {address: address, type: type, reason: e.toString()}
					})
				} catch (e) {

				}
				console.log(e.toString())
				toast(t("Fail"))
			} finally {
				setLoading(false)
			}
		} else {
			toast(t("Please connect wallet first"))
		}
	}, [withdraw, nodeWithdraw])

	// 激活授权
	const handleActiveApprove = useCallback(async () => {
		if (chainId != BUY_CHAIN_ID) {
			await switchNetworkAsync(BUY_CHAIN_ID)
		}
		if (isConnected) {
			setLoading(true)
			try {
				const txHash = await activeApprove()
				const tx = await waitForTransaction({...txHash, confirmations: 3, timeout: 600000})
				toast(t("SUCCESS"))
				setShowActiveConfirm(0)
				console.log("交易ID", tx)
			} catch (e) {
				try {
					await axios.request(ActiveBASE + '/fail-reason/add', {
						method: 'POST',
						data: {address: address, type: 3, reason: e.toString()}
					})
				} catch (e) {

				}
				toast(t("Fail"))
			} finally {
				setLoading(false)
			}
		} else {
			toast(t("Please connect wallet first"))
		}
	}, [activeApprove])

	// 激活
	const handleActive = useCallback(async (number) => {
		if (chainId != BUY_CHAIN_ID) {
			await switchNetworkAsync(BUY_CHAIN_ID)
		}
		if (isConnected) {
			setLoading(true)
			try {
				const rsp = await axios.request(ActiveBASE + '/active/node', {
					method: 'POST',
					data: {address: address, number: number}
				})
				if (rsp.status != 200) {
					return toast(t("Fail"))
				}
				if (rsp.data.code != 0) {
					return toast(rsp.data.message)
				}
				// 激活
				const {request} = await prepareWriteContract({
					address: ACTIVE_ADDR,
					abi: activeAbi,
					functionName: 'active',
					args: [rsp.data.data.number, rsp.data.data.timeout, rsp.data.data.sig],
					chainId: BUY_CHAIN_ID,
				})
				const {hash} = await writeContract(request)
				const tx = await waitForTransaction({hash, confirmations: 3, chainId: BUY_CHAIN_ID, timeout: 600000})
				toast(t("SUCCESS"))
				console.log("交易ID", tx)
				setShowActiveConfirm(0)
				refreshNodeDetail(nodeClient, buyClient)
			} catch (e) {
				try {
					await axios.request(ActiveBASE + '/fail-reason/add', {
						method: 'POST',
						data: {address: address, type: 4, reason: e.toString()}
					})
				} catch (e) {

				}
				console.log(e.toString())
				toast(t("Fail"))
			} finally {
				setLoading(false)
				setShowActiveConfirm(0)
			}
		} else {
			toast(t("Please connect wallet first"))
		}
	}, [activeApprove, address, nodeClient, buyClient])


	const CopyToClipboard = () => {
		let text = `${window.location.origin}?inviter=${address}`;
		copy(text);
		toast(t`SUCCESS`);
	}


	return (
		<div className="relative py-24 sm:py-32">
			{showModal == 1 ? (
				<div
					className='fixed fadeIn left-0 top-0 w-full h-full bg-transparent z-[1] backdrop-filter backdrop-blur-md'>
					<div className='relative flex flex-col items-center justify-center w-full h-full text-white'>
						<div
							className='relative w-full mx-8 sm:w-[540px] bg-gradient-to-r from-[#111937] to-[#0F2835] p-10 rounded-xl flex items-center flex-col justify-center'>
							<XMarkIcon onClick={() => setShowModal(0)}
									   className='absolute w-6 h-6 cursor-pointer top-3 right-3'/>
							<div className='text-2xl font-bold text-left'>{t`Withdraw`}</div>
							<p className="mt-10 text-lg">{t`Tokens`}</p>
							<div className="flex flex-row items-center justify-center gap-10 mt-5">
								<label className="container">
									USDT
									<input type="radio" value="USDT" checked={selectedOption === 'USDT'}
										   onChange={() => setSelectedOption('USDT')}></input>
									<span class="checkmark"></span>
								</label>
								<label class="container">
									MEMO
									<input disabled={false} type="radio" value="MEMO"
										   checked={selectedOption === 'MEMO'}
										   onChange={() => setSelectedOption('MEMO')}></input>
									<span class="checkmark"></span>
								</label>
								<label class="container">
									DATAD
									<input disabled={false} type="radio" value="DATAD"
										   checked={selectedOption === 'DATAD'}
										   onChange={() => setSelectedOption('DATAD')}></input>
									<span class="checkmark"></span>
								</label>
							</div>
							<p className="mt-10">{t`Number`}</p>
							<input type="text" value={withdrawNum}
								   onChange={(e) => handleSetBigWithdrawNum(e.target.value)}
								   placeholder="Number"
								   className="w-full p-3 mt-2 text-center bg-transparent border border-white rounded-xl max-w-[350px]"></input>
							<div className="flex justify-center w-full">
								<div onClick={handleWithdraw}
									 className="bg-gradient-to-r from-[#1A61ED] to-[#11BAE3] py-3 px-20 rounded-xl mt-10 cursor-pointer text-center uppercase">{loading ?
									<Spinner pulse/> : <> </>}{t`Withdraw`}
								</div>
							</div>
						</div>
					</div>
				</div>) : null}

			{showActiveConfirm == 1 ? (
				<div
					className='fixed fadeIn left-0 top-0 w-full h-full bg-transparent z-[1] backdrop-filter backdrop-blur-md'>
					<div className='relative flex flex-col items-center justify-center w-full h-full text-white'>
						<div
							className='relative w-full mx-8 sm:w-[540px] bg-gradient-to-r from-[#111937] to-[#0F2835] p-10 rounded-xl flex items-center flex-col justify-center'>
							<XMarkIcon onClick={() => setShowActiveConfirm(0)}
									   className='absolute w-6 h-6 cursor-pointer top-3 right-3'/>
							<div className='text-2xl font-bold text-left'>{t`Node-Active`}</div>
							<p className="mt-10 text-lg">{t`Node active will burn `}{formatUnits(activeAmount, 18)}MemoDAO</p>


							<div className="flex justify-center w-full">
								{parseFloat(formatUnits(activeAmount, 18)) > parseFloat(formatUnits(activeAllowance, 18)) ?
									<div onClick={() => handleActiveApprove()}
										 className="bg-gradient-to-r from-[#1A61ED] to-[#11BAE3] py-3 px-10 rounded-xl mt-10 cursor-pointer text-center uppercase">{loading ?
										<Spinner pulse/> : <> </>}{t(`Approve`)}
									</div> : <div onClick={() => handleActive(activeNumber)}
												  className="bg-gradient-to-r from-[#1A61ED] to-[#11BAE3] py-3 px-10 rounded-xl mt-10 cursor-pointer text-center uppercase">{loading ?
										<Spinner pulse/> : <> </>}{t(`Node-Active`)}
									</div>}
								<div onClick={() => setShowActiveConfirm(0)}
									 className="ml-[20px] bg-gradient-to-r from-[#52525b] to-[#52525b] py-3 px-10 rounded-xl mt-10 cursor-pointer text-center uppercase">{t`CANCEL`}
								</div>
							</div>
						</div>
					</div>
				</div>) : null}

			<div className="px-6 mx-auto text-white max-w-7xl lg:px-8">
				<div className="flex flex-col items-center justify-center mx-auto sm:text-center">
					<div className="flex flex-col justify-between w-full sm:flex-row gap-5">
						<div className="text-3xl font-bold tracking-tight sm:text-5xl">{t`My Nodes`}</div>
						<div className="flex flex-col items-center justify-center gap-5 sm:flex-row">
							{isConnected?
								<div
									className="bg-gradient-to-r from-[#0D2B4E] to-[#0D3955] py-2 px-7 border border-[#104370] rounded-md cursor-pointer hover:translate-y-[-5px] transition-transform duration-700 ease-in-out"
									onClick={() => CopyToClipboard()}>{t`Invitation code`}({t`Invited`}:{childrenCount})
								</div> : <div
									className="bg-[#6b7280] text-[#d4d4d8] py-2 px-7 border border-[#6b7280] rounded-md cursor-pointer hover:translate-y-[-5px] transition-transform duration-700 ease-in-out">{t`Invitation code`}
								</div>}
							{isConnected ?

								<div
									className="bg-gradient-to-r from-[#0D2B4E] to-[#0D3955] py-2 px-7 border border-[#104370] rounded-md cursor-pointer hover:translate-y-[-5px] transition-transform duration-700 ease-in-out"
									onClick={() => setShowModal(1)}>{t`Withdraw`}
								</div> : null}
						</div>
					</div>
					<div className="flex flex-row justify-between w-full mt-12 text-xl">
						<div>USDT: {usdt}</div>
						<div>MEMO: {Math.floor(memo * 100) / 100}</div>
						<div>DATAD: {Math.floor(datad * 100) / 100}</div>
					</div>
					<div
						className="bg-gradient-to-r mt-12 rounded-xl from-[#0D1732] to-[#0C2530] w-full overflow-auto text-white">
						<div className="_md:w-[1225px]">
							<div
								className="flex flex-row p-4 py-5 text-base font-medium leading-5 text-center rounded-xl">
								<div className='w-1/5'>{t`Node-Number`}</div>
								<div className='w-1/5'>{t`Node-Expire`}</div>
								<div className='w-1/5'>{t`Share-DATAD`}</div>
								<div className='w-1/5'>{t`Share-MEMO`}</div>
								<div className='w-1/5'>{t`Action`}</div>
							</div>
							<img src="./img/vision-bottom-line.png" className="my-1"></img>
							{nodes.map((item, index) =>
								<div key={index}
									 className="flex items-center flex-row p-4 py-5 text-base font-medium leading-5 text-center rounded-xl">
									<div className='w-1/5'>{item.number}</div>
									<div
										className='w-1/5'>{item.activeTime > 0 ? dayjs(item.expiredTime * 1000).format("YYYY-MM-DD HH:mm:ss") : "-"}</div>
									<div
										className='w-1/5'>{item.activeTime > 0 ? Math.floor(item.datad * 100) / 100 : "-"}</div>
									<div
										className='w-1/5'>{item.activeTime > 0 ? Math.floor(item.memo * 100) / 100 : "-"}</div>
									{/*<div className='w-1/5'>-</div>*/}
									{/*<div className='w-1/5'>-</div>*/}
									{/*<div className='w-1/5'>-</div>*/}
									{/*<div className='w-1/5'>-</div>*/}
									<div className='w-1/5'>
										{!item.activated ?
											<div
												className="bg-gradient-to-r from-[#1A61ED] to-[#11BAE3] py-3 px-10 rounded-xl cursor-pointer text-center"
												onClick={() => {
													setShowActiveConfirm(1);
													setActiveNumber(item.number)
												}}>{t(`Node-Active`)}</div>
											// parseFloat(formatUnits(activeAmount, 18)) > parseFloat(formatUnits(activeAllowance, 18)) ?
											//
											// 	<div
											// 		className="bg-gradient-to-r from-[#1A61ED] to-[#11BAE3] py-3 px-10 rounded-xl cursor-pointer text-center"
											// 		onClick={handleActiveApprove}>{loading?<Spinner pulse/>:<> </>}{t(`Active-Approve`)}</div> :
											// 	<div
											// 		className="bg-gradient-to-r from-[#1A61ED] to-[#11BAE3] py-3 px-10 rounded-xl cursor-pointer text-center"
											// 		onClick={() => {
											// 			handleActive(item.number)
											// 		}}>{loading?<Spinner pulse/>:<> </>}{t(`Node-Active`)}</div>
											: item.activeTime == 0 ? t(`Activating`) : t(`Activated`)
										}
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
