import * as React from 'react'
import '../css/project-base.css'
import '../css/animations.css';
import Navbar from './Home/Navbar';
import Footer from './Home/Footer';
import useSWR from "swr";
import { BASE } from "../utils/http";
import { useAccount, useContractWrite, useSignMessage } from "wagmi";
import axios from "axios";
import { useCallback, useState } from "react";
import dayjs from "dayjs";
import airdropAbi from '../assets/abi/Airdrop.json'
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

/**
 * 空投合约
 * @type {string}
 */
const AIRDROP_ADDRESS = '0x5dbD684c8aAd905E01F2ae6dCcf2cE35e75c4bAC'

const fetcher = url => axios.get(url).then(res => res.data)

const ClaimButton = ({item, onClaim}) => {
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)

  return (
    <button
      disabled={loading}
      type="button"
      className="inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-blue-600 hover:text-blue-800 disabled:opacity-50 disabled:pointer-events-none"
      onClick={async () => {
        setLoading(true)
        await onClaim(item)
        setLoading(false)
      }}
    >
      {t`Claim`}
    </button>
  )
}

const WithdrawalLog = () => {
  const {t} = useTranslation()

  const {address} = useAccount()
  const {signMessageAsync} = useSignMessage()
  const {writeAsync} = useContractWrite({
    abi: airdropAbi,
    address: AIRDROP_ADDRESS,
    functionName: 'claimAirdrop'
  })

  const {data} = useSWR(() => {
    return BASE + `/v1/coin/withdrawal/logs?user=${address}&page=1&limit=100`
  }, fetcher)

  const onClaim = useCallback(async (item) => {
    try {
      const timestamp = parseInt(Date.now() / 1000)
      const message = `Claim Airdrop
Id: ${item.id}
Timestamp: ${timestamp}`

      const sig = await signMessageAsync({
        message,
      })

      const rsp = await axios.request(BASE + '/v1/coin/withdrawal/sign', {
        method: 'POST',
        data: {
          id: item.id,
          timestamp,
          sig
        }
      })
      if (rsp.data.code === 0) {
        const data = rsp.data.data

        const hash = await writeAsync({
          args: [
            data.token,
            data.amount,
            data.nonce,
            data.deadline,
            data.sig,
          ]
        })

        console.log(hash)

        toast.success(t`Successful operation`)
      }
    } catch (e) {
      console.log(e)
    }
  }, [signMessageAsync, writeAsync, t])

  return (
    <div className="h-screen App bg-[#0C0B15]">
      <div className='relative w-full h-screen body'>
        <div className='relative min-h-[650px]'>
          <img className='absolute top-0' src="/img/bg-intro.png"></img>
          <div className='absolute left-0 top-0 w-full h-full bg-gradient-to-b from-[#0A3641EE] to-[#0C0B15]'></div>
          {/* Lightning effect - left */}
          <img src="/img/left-eclipse4.png" className='absolute left-8 bottom-32'></img>
          <img src="/img/left-eclipse1.png" className='absolute left-48 bottom-[265px]'></img>
          <img src="/img/left-eclipse2.png" className='absolute left-48 bottom-[220px]'></img>
          <img src="/img/left-eclipse3.png" className='absolute left-56 bottom-64'></img>
          {/* Lightning effect - right */}
          <img src="/img/right-eclipse4.png" className='absolute right-8 bottom-32'></img>
          <img src="/img/right-eclipse1.png" className='absolute right-52 bottom-[265px]'></img>
          <img src="/img/right-eclipse2.png" className='absolute right-60 bottom-[255px]'></img>
          <img src="/img/right-eclipse3.png" className='absolute right-52 bottom-56'></img>

          <Navbar/>

          <div className={'relative py-24 sm:py-32'}>
            <div className="px-6 mx-auto text-white max-w-7xl lg:px-8">
              <div className="flex flex-col items-center justify-center mx-auto sm:text-center">
                <div className="flex flex-row justify-between w-full">
                  <h2 className="text-3xl font-bold tracking-tight sm:text-5xl">{t`Withdrawal`}</h2>
                </div>
              </div>

              <div className="flex flex-col mt-16 bg-white rounded-lg">
                <div className="-m-1.5 overflow-x-auto">
                  <div className="p-1.5 min-w-full inline-block align-middle">
                    <div className="overflow-hidden">
                      <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                        <thead>
                        <tr>
                          <th scope="col"
                              className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase">{t`ID`}
                          </th>
                          <th scope="col"
                              className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase">{t`Symbol`}
                          </th>
                          <th scope="col"
                              className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase">{t`Time`}
                          </th>
                          <th scope="col"
                              className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase">{t`Amount`}
                          </th>
                          <th scope="col"
                              className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase">{t`Fee`}
                          </th>
                          <th scope="col"
                              className="px-6 py-3 text-end text-xs font-medium text-gray-500 uppercase">{t`Action`}
                          </th>
                        </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                        {
                          (data?.data?.data ?? []).map(item => (
                            <tr key={item.id}>
                              <td
                                className="text-left px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">
                                {item.id}
                              </td>
                              <td
                                className="text-left px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">
                                {item.coinId}
                              </td>
                              <td
                                className="text-left px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">
                                {dayjs(item.createTime).format('YYYY-MM-DD hh:mm:ss')}
                              </td>
                              <td
                                className="text-left px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">
                                {item.num}
                              </td>
                              <td
                                className="text-left px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">
                                {item.fee}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-end text-sm font-medium">
                                <ClaimButton item={item} onClaim={onClaim}/>
                              </td>
                            </tr>
                          ))
                        }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer/>
      </div>
    </div>
  );
}

export default WithdrawalLog