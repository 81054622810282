import './App.css';
import Landing from './components/Landing';
import Mission from './components/Mission';
import Policy from './components/Policy';
import Nodes from './components/Nodes';
import MyNode from './components/MyNode';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import WithdrawalLog from "./components/WithdrawalLog";
import Console from "./components/Console";
import Login from "./components/Login";
import Purchase from "./components/Purchase";

function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path='/' element={<Landing/>}></Route>
				<Route path='/mission' element={<Mission/>}></Route>
				<Route path='/policy' element={<Policy/>}></Route>
				<Route path='/nodes' element={<Nodes/>}></Route>
				<Route path='/mynode' element={<MyNode/>}></Route>
				<Route path='/console' element={<Console/>}></Route>
				{/*<Route path='/purchase' element={<Purchase/>}></Route>*/}
				<Route path='/mynode/withdrawal' element={<WithdrawalLog/>}></Route>、
				<Route path='/login' element={<Login/>}></Route>
			</Routes>
		</BrowserRouter>
	);
}

export default App;
