import {useEffect, useCallback, useState} from "react";

import {toast} from 'react-toastify';

import {useAccount, useChainId, useContractRead, useContractWrite, usePublicClient, useSwitchNetwork} from "wagmi";
import {waitForTransaction, writeContract} from '@wagmi/core'
import {ACTIVE_ADDR, BUY_ADDR, BUY_CHAIN_ID, NODE_ADDR, NODE_CHAIN_ID} from '../../../config/config'

import activeAbi from "../../../assets/abi/Active.json"
import * as React from "react";
import {formatUnits, parseUnits} from "viem";
import {useTranslation} from "react-i18next";
import Spinner from "@rsuite/icons/legacy/Spinner";
import {BigNumber} from "bignumber.js";

export default function ConsoleActive() {
	const {t} = useTranslation()
	const {isConnected} = useAccount()
	const [loading, setLoading] = useState(false)
	const [memoAmount, setMemoAmount] = useState(0)
	const [addressReceive, setAddressReceive] = useState("")
	const {switchNetworkAsync} = useSwitchNetwork()
	const chainId = useChainId()

	// 激活所需MEMO
	const {refetch: refetchActiveMemoAmount} = useContractRead({
		address: ACTIVE_ADDR,
		abi: activeAbi,
		functionName: 'activeMemoAmount',
		args: [],
		chainId: BUY_CHAIN_ID
	})

	// 默认产出
	const {refetch: refetchAddressReceiveMemo} = useContractRead({
		address: ACTIVE_ADDR,
		abi: activeAbi,
		functionName: 'addressReceiveMemo',
		args: [],
		chainId: BUY_CHAIN_ID
	})


	// 设置激活所需MEMO
	const {writeAsync: editActiveMemoAmount} = useContractWrite({
		address: ACTIVE_ADDR,
		abi: activeAbi,
		functionName: 'editActiveMemoAmount',
		args: [memoAmount==""?0:parseUnits((new BigNumber(memoAmount).toString()), 18)],
		chainId: BUY_CHAIN_ID
	})

	const {writeAsync: editAddressReceiveMemo} = useContractWrite({
		address: ACTIVE_ADDR,
		abi: activeAbi,
		functionName: 'editAddressReceiveMemo',
		args: [addressReceive],
		chainId: BUY_CHAIN_ID
	})


	useEffect(() => {
		refetchActiveMemoAmount().then((data => {
			setMemoAmount(formatUnits(data.data, 18))
		}))
		refetchAddressReceiveMemo().then((data => {
			setAddressReceive(data.data)
		}))
	}, [])

	const changeMemoAmount = (amount) => {
		setMemoAmount(amount)
	}

	const changeAddressReceive = (address) => {
		setAddressReceive(address)
	}


	// 设置默认产出
	const [editActiveMemoAmountLoading,setEditActiveMemoAmountLoading] = useState(false)
	const handleEditActiveMemoAmount = useCallback(async () => {
		if (chainId != BUY_CHAIN_ID) {
			await switchNetworkAsync(BUY_CHAIN_ID)
		}
		if (isConnected) {
			setEditActiveMemoAmountLoading(true)
			try {
				const txHash = await editActiveMemoAmount()
				const tx = await waitForTransaction({...txHash, confirmations: 3})
				toast(t("Success"))
				console.log(tx)
			} catch (e) {
				console.log(e.toString())
				toast(t("Fail"))
			} finally {
				setEditActiveMemoAmountLoading(false)
			}
		}
	}, [isConnected, editActiveMemoAmount])

	const[editAddressReceiveMemoLoading,setEditAddressReceiveMemoLoading] = useState(false)
	const handleEditAddressReceiveMemo = useCallback(async () => {
		if (chainId != BUY_CHAIN_ID) {
			await switchNetworkAsync(BUY_CHAIN_ID)
		}
		if (isConnected) {
			setEditAddressReceiveMemoLoading(true)
			try {
				const txHash = await editAddressReceiveMemo()
				const tx = await waitForTransaction({...txHash, confirmations: 3})
				toast(t("Success"))
				console.log(tx)
			} catch (e) {
				console.log(e.toString())
				toast(t("Fail"))
			} finally {
				setEditAddressReceiveMemoLoading(false)
			}
		} else {
			toast(t("Please connect wallet first"))
		}
	}, [isConnected, editAddressReceiveMemo])

	return (
		<>
			<div className="w-full flex items-center p-[10px]">
				<div className="mr-[20px]">激活所需MEMO：</div>
				<div className="flex-1">
					<input type="text" value={memoAmount}
						   onChange={(e) => changeMemoAmount(e.target.value)}
						   className="w-full p-2 bg-transparent border border-white rounded-xl"></input>
				</div>
				<div className="ml-[20px]">
					<div
						className="bg-gradient-to-r from-[#1A61ED] to-[#11BAE3] py-3 px-5 rounded-xl cursor-pointer text-center"
						onClick={handleEditActiveMemoAmount}>{editActiveMemoAmountLoading ? <Spinner pulse/> : <> </>}保存
					</div>
				</div>
			</div>
			<div className="w-full flex items-center p-[10px]">
				<div className="mr-[20px]">激活MEMO收款地址：</div>
				<div className="flex-1">
					<input type="text" value={addressReceive}
						   onChange={(e) => changeAddressReceive(e.target.value)}
						   className="w-full p-2 bg-transparent border border-white rounded-xl"></input>
				</div>
				<div className="ml-[20px]">
					<div
						className="bg-gradient-to-r from-[#1A61ED] to-[#11BAE3] py-3 px-5 rounded-xl cursor-pointer text-center"
						onClick={handleEditAddressReceiveMemo}>{editAddressReceiveMemoLoading ? <Spinner pulse/> : <> </>}保存
					</div>
				</div>
			</div>
		</>
	)
}

