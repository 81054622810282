import {useEffect, useCallback, useState} from "react";

import {toast} from 'react-toastify';

import {useAccount, useChainId, useContractRead, useContractWrite, usePublicClient, useSwitchNetwork} from "wagmi";
import {waitForTransaction, writeContract} from '@wagmi/core'
import {BUY_ADDR, BUY_CHAIN_ID, NODE_ADDR, NODE_CHAIN_ID} from '../../../config/config'

import nodeAbi from "../../../assets/abi/Node.json"
import * as React from "react";
import {formatUnits, parseUnits} from "viem";
import {useTranslation} from "react-i18next";
import {bscTestnet} from "wagmi/chains";
import BigNumber from "bignumber.js";
import Spinner from "@rsuite/icons/legacy/Spinner";

export default function ConsoleNode() {
	const {t} = useTranslation()
	const {isConnected} = useAccount()
	const [outMemo, setOutMemo] = useState(0)
	const [secondOutMemo, setSecondOutMemo] = useState(0)
	const [outDatad, setOutDatad] = useState(0)
	const [secondOutDatad, setSecondOutDatad] = useState(0)
	const [nodeOut, setNodeOut] = useState({min: "", max: "", memo: "", datad: ""}) // 批量修改产出
	const [nodeAdd, setNodeAdd] = useState({number: "", address: ""})
	const [userAddress, setUserAddress] = useState("")
	const [nodeNumber, setNodeNumber] = useState("")

	const [outNodeMin, setOutNodeMin] = useState("")
	const [outNodeMax, setOutNodeMax] = useState("")
	const [outNodeMemo, setOutNodeMemo] = useState("")
	const [outNodeDatad, setOutNodeDatad] = useState("")
	const [loading, setLoading] = useState(false)
	const {switchNetworkAsync} = useSwitchNetwork()
	const chainId = useChainId()

	// 默认产出
	const {refetch: refetchOutMemo} = useContractRead({
		address: NODE_ADDR,
		abi: nodeAbi,
		functionName: 'outMemo',
		args: [],
		watch: true,
		chainId: NODE_CHAIN_ID
	})

	// 默认产出
	const {refetch: refetchOutDatad} = useContractRead({
		address: NODE_ADDR,
		abi: nodeAbi,
		functionName: 'outDatad',
		args: [],
		watch: true,
		chainId: NODE_CHAIN_ID
	})


	// 设置默认产出
	const {writeAsync: editDefaultOutDatad} = useContractWrite({
		address: NODE_ADDR,
		abi: nodeAbi,
		functionName: 'editDefaultOutDatad',
		args: [secondOutDatad],
		chainId: NODE_CHAIN_ID
	})

	const {writeAsync: editDefaultOutMemo} = useContractWrite({
		address: NODE_ADDR,
		abi: nodeAbi,
		functionName: 'editDefaultOutMemo',
		args: [secondOutMemo],
		chainId: NODE_CHAIN_ID
	})

	// 节点添加
	const {writeAsync: addNode} = useContractWrite({
		address: NODE_ADDR,
		abi: nodeAbi,
		functionName: 'add',
		args: [userAddress, nodeNumber],
		chainId: NODE_CHAIN_ID
	})

	// 节点批量修改产出
	const {writeAsync: editNodeOut} = useContractWrite({
		address: NODE_ADDR,
		abi: nodeAbi,
		functionName: 'editNodeOut',
		args: [outNodeMin, outNodeMax, outNodeMemo, outNodeDatad],
		chainId: NODE_CHAIN_ID
	})


	useEffect(() => {
		refetchOutDatad().then((data) => {
			const amount = new BigNumber(formatUnits(data.data, 18)).times(24 * 3600)
			setOutDatad(amount.decimalPlaces(5))
			setSecondOutDatad(parseUnits(amount.decimalPlaces(5).div(24 * 3600).toString(), 18))
		})
		refetchOutMemo().then((data) => {
			const amount = new BigNumber(formatUnits(data.data, 18)).times(24 * 3600)
			setOutMemo(amount.decimalPlaces(5))
			setSecondOutMemo(parseUnits(amount.decimalPlaces(5).div(24 * 3600).toString(), 18))
		})
	}, [])

	const changeDatad = (amount) => {
		setOutDatad(amount)
		if (!parseInt(amount)) {
			amount = 0
		}
		amount = parseUnits(new BigNumber(amount).div(24 * 3600).toString(), 18)
		setSecondOutDatad(amount)
	}

	const changeMemo = (amount) => {
		setOutMemo(amount)
		if (!parseInt(amount)) {
			amount = 0
		}
		amount = parseUnits(new BigNumber(amount).div(24 * 3600).toString(), 18)
		setSecondOutMemo(amount)
	}


	// 设置默认产出
	const [editOutDatadLoading, setEditOutDatadLoading] = useState(false)
	const handleEditOutDatad = useCallback(async () => {
		if (chainId != NODE_CHAIN_ID) {
			await switchNetworkAsync(NODE_CHAIN_ID)
		}
		if (isConnected) {
			setEditOutDatadLoading(true)
			try {
				const txHash = await editDefaultOutDatad()
				const tx = await waitForTransaction({...txHash, confirmations: 3})
				toast(t("Success"))
				console.log(tx)
			} catch (e) {
				toast(t("Fail"))
			} finally {
				setEditOutDatadLoading(false)
			}
		}
	}, [isConnected, editDefaultOutDatad])

	const [editOutMemoLoading, setEditOutMemoLoading] = useState(false)
	const handleEditOutMemo = useCallback(async () => {
		if (chainId != NODE_CHAIN_ID) {
			await switchNetworkAsync(NODE_CHAIN_ID)
		}
		if (isConnected) {
			setEditOutMemoLoading(true)
			try {
				const txHash = await editDefaultOutMemo()
				const tx = await waitForTransaction({...txHash, confirmations: 3})
				toast(t("Success"))
				console.log(tx)
			} catch (e) {
				toast(t("Fail"))
			} finally {
				setEditOutMemoLoading(false)
			}
		} else {
			toast(t("Please connect wallet first"))
		}
	}, [isConnected, editDefaultOutMemo])

	// 添加节点
	const handleAddNode = useCallback(async () => {
		if (isConnected) {
			try {
				const txHash = await addNode()
				const tx = await waitForTransaction({...txHash, confirmations: 3})
				toast(t("Success"))
				setUserAddress("")
				setNodeNumber("")
				console.log(tx)
			} catch (e) {
				toast(t("Fail"))
			}
		} else {
			toast(t("Please connect wallet first"))
		}
	}, [isConnected, addNode])

	const changeOutNodeMemo = (amount) => {
		if (!parseInt(amount)) {
			amount = 0
		}
		amount = parseUnits(new BigNumber(amount).div(24 * 3600).toString(), 18)
		setOutNodeMemo(amount)
	}

	const changeOutNodeDatad = (amount) => {
		if (!parseInt(amount)) {
			amount = 0
		}
		amount = parseUnits(new BigNumber(amount).div(24 * 3600).toString(), 18)
		setOutNodeDatad(amount)
	}

	// 批量修改节点产出
	const [editNodeOutLoading, setEditNodeOutLoading] = useState(false)
	const handleEditNodeOut = useCallback(async () => {
		if (chainId != NODE_CHAIN_ID) {
			await switchNetworkAsync(NODE_CHAIN_ID)
		}
		if (isConnected) {
			setEditNodeOutLoading(true)
			try {
				const txHash = await editNodeOut()
				const tx = await waitForTransaction({...txHash, confirmations: 3})
				toast(t("Success"))
				setOutNodeDatad("")
				setOutNodeMin("")
				setOutNodeMemo("")
				setOutNodeMax("")
				console.log(tx)
			} catch (e) {
				toast(t("Fail"))
			} finally {
				setEditNodeOutLoading(false)
			}
		} else {
			toast(t("Please connect wallet first"))
		}
	}, [isConnected, addNode])

	return (
		<>
			<div className="w-full flex items-center p-[10px]">
				<div className="mr-[20px]">每日产出MEMO：</div>
				<div className="flex-1">
					<input type="text" value={outMemo}
						   onChange={(e) => changeMemo(e.target.value)}
						   className="w-full p-2 bg-transparent border border-white rounded-xl"></input>
				</div>
				<div className="ml-[20px]">
					<div
						className="bg-gradient-to-r from-[#1A61ED] to-[#11BAE3] py-3 px-5 rounded-xl cursor-pointer text-center"
						onClick={handleEditOutMemo}>{editOutMemoLoading ? <Spinner pulse/> : <> </>}保存
					</div>
				</div>
			</div>
			<div className="w-full flex items-center p-[10px]">
				<div className="mr-[20px]">每日产出DATAD：</div>
				<div className="flex-1">
					<input type="text" value={outDatad}
						   onChange={(e) => changeDatad(e.target.value)}
						   className="w-full p-2 bg-transparent border border-white rounded-xl"></input>
				</div>
				<div className="ml-[20px]">
					<div
						className="bg-gradient-to-r from-[#1A61ED] to-[#11BAE3] py-3 px-5 rounded-xl cursor-pointer text-center"
						onClick={handleEditOutDatad}>{editOutDatadLoading ? <Spinner pulse/> : <> </>}保存
					</div>
				</div>
			</div>

			{/*<div className="w-full flex items-center p-[10px]">*/}
			{/*	<div className="mr-[20px]">节点添加：</div>*/}
			{/*	<div className="flex-1 flex">*/}
			{/*		<input type="text" value={userAddress} placeholder="请输入用户地址"*/}
			{/*			   onChange={(e) => setUserAddress(e.target.value)}*/}
			{/*			   className="w-full p-2 bg-transparent border border-white rounded-xl"></input>*/}
			{/*		<input type="text" value={nodeNumber} placeholder="请输入节点编号"*/}
			{/*			   onChange={(e) => setNodeNumber(e.target.value)}*/}
			{/*			   className="w-full ml-[20px] p-2 bg-transparent border border-white rounded-xl"></input>*/}
			{/*	</div>*/}
			{/*	<div className="ml-[20px]">*/}
			{/*		<div*/}
			{/*			className="bg-gradient-to-r from-[#1A61ED] to-[#11BAE3] py-3 px-5 rounded-xl cursor-pointer text-center"*/}
			{/*			onClick={handleAddNode}>保存*/}
			{/*		</div>*/}
			{/*	</div>*/}
			{/*</div>*/}

			<div className="w-full flex items-center p-[10px]">
				<div className="mr-[20px]">节点产出：</div>
				<div className="flex-1 flex">
					<input type="text" placeholder="请输入最小节点编号"
						   onChange={(e) => setOutNodeMin(e.target.value)}
						   className="w-full p-2 bg-transparent border border-white rounded-xl"></input>
					<input type="text" placeholder="请输入最大节点编号"
						   onChange={(e) => setOutNodeMax(e.target.value)}
						   className="w-full  ml-[20px] p-2 bg-transparent border border-white rounded-xl"></input>
					<input type="text" placeholder="请输入MEMO每日产出"
						   onChange={(e) => changeOutNodeMemo(e.target.value)}
						   className="w-full ml-[20px] p-2 bg-transparent border border-white rounded-xl"></input>
					<input type="text" placeholder="请输入DATAD每日产出"
						   onChange={(e) => changeOutNodeDatad(e.target.value)}
						   className="w-full ml-[20px] p-2 bg-transparent border border-white rounded-xl"></input>
				</div>
				<div className="ml-[20px]">
					<div
						className="bg-gradient-to-r from-[#1A61ED] to-[#11BAE3] py-3 px-5 rounded-xl cursor-pointer text-center"
						onClick={handleEditNodeOut}>{editNodeOutLoading ? <Spinner pulse/> : <> </>}保存
					</div>
				</div>
			</div>
		</>
	)
}

