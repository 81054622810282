import {useEffect, useCallback, useState} from "react";

import {toast} from 'react-toastify';

import {useAccount, useContractRead, erc20ABI, useContractWrite, useSwitchNetwork, useChainId} from "wagmi";
import {waitForTransaction} from '@wagmi/core'
import {BUY_ADDR, BUY_CHAIN_ID, USDT_ADDR} from '../../config/config'

import {useTranslation} from "react-i18next";
import buyAbi from "../../assets/abi/Buy.json"
import {formatUnits, zeroAddress} from "viem";
import * as React from "react";
import {maxUint256} from "viem";
import Spinner from "@rsuite/icons/legacy/Spinner";
import axios from "axios";
import {ActiveBASE} from "../../utils/http";

export default function Nodes() {
	const {t} = useTranslation()

	const [totalAmount, setTotalAmount] = useState(0);
	const [count, setCount] = useState(1);
	const account = useAccount();
	const [nodePrice, setNodePrice] = useState("0")
	const [inviter, setInviter] = useState(sessionStorage.getItem("inviter") ? sessionStorage.getItem("inviter") : "")
	const [allowanceSuccess, setAllowanceSuccess] = useState(false)
	const [buySuccess, setBuySuccess] = useState(false)
	const [buySuccessTimes, setBuySuccessTimes] = useState(0)
	const [loading, setLoading] = useState(false)
	const {switchNetworkAsync} = useSwitchNetwork()
	const chainId = useChainId()
	const {isConnected, address} = useAccount()

	// 节点价格
	const {data: price} = useContractRead({
		address: BUY_ADDR,
		abi: buyAbi,
		functionName: 'price',
		args: [],
		watch: true,
		chainId: BUY_CHAIN_ID
	})


	// 授权数量
	const {data: allowance} = useContractRead({
		address: USDT_ADDR,
		abi: erc20ABI,
		functionName: 'allowance',
		args: [account.address, BUY_ADDR],
		watch: true,
		chainId: BUY_CHAIN_ID
	})

	// 开始授权
	const {writeAsync: approve} = useContractWrite({
		address: USDT_ADDR,
		abi: erc20ABI,
		functionName: 'approve',
		args: [BUY_ADDR, maxUint256],
		chainId: BUY_CHAIN_ID
	})

	// 购买
	const {writeAsync: buy} = useContractWrite({
		address: BUY_ADDR,
		abi: buyAbi,
		functionName: 'buy',
		args: [count, inviter],
		chainId: BUY_CHAIN_ID
	})

	// 邀请人
	const {refetch: refetchInviter} = useContractRead({
		address: BUY_ADDR,
		abi: buyAbi,
		functionName: 'userInviter',
		args: [account.address],
		watch: true,
		chainId: BUY_CHAIN_ID
	})


	// 获取邀请人
	useEffect(() => {
		if (isConnected) {
			axios.request(ActiveBASE + '/user/inviter', {
				method: 'POST',
				data: {address: address}
			}).then((res) => {
				if (res.status == 200 && res.data.code == 0 && res.data.data.inviter != "") {
					setInviter(res.data.data.inviter)
				}
			})
		}
	}, [isConnected, address])

	// 授权
	const handleApprove = useCallback(async () => {
		if (inviter == "") {
			toast(t("Enter Your Referral Code"))
			return
		}
		if (chainId != BUY_CHAIN_ID) {
			await switchNetworkAsync(BUY_CHAIN_ID)
		}
		setLoading(true)
		if (account.isConnected) {
			try {
				const txHash = await approve()
				const tx = await waitForTransaction({...txHash, confirmations: 3, timeout: 600000})
				toast(t("Approve Success"))
			} catch (e) {
				try {
					await axios.request(ActiveBASE + '/fail-reason/add', {
						method: 'POST',
						data: {address: account.address, type: 1, reason: e.toString()}
					})
				} catch (e) {

				}
				toast(t("Approve Fail"))
			} finally {
				setLoading(false)
			}
		} else {
			toast(t("Please connect wallet first"))
		}
	}, [approve])

	// 购买
	const handleBuy = useCallback(async () => {
		if (inviter == "") {
			toast(t("Inviter error"))
			return
		}
		// if (inviter == account.address) {
		// 	toast(t("Inviter error"))
		// 	return
		// }
		if (chainId != BUY_CHAIN_ID) {
			await switchNetworkAsync(BUY_CHAIN_ID)
		}
		setBuySuccess(false)
		setLoading(true)
		if (account.isConnected) {
			try {
				const txHash = await buy()
				const tx = await waitForTransaction({...txHash, confirmations: 3, timeout: 600000})
				toast(t("SUCCESS"))
				setBuySuccess(true)
				setBuySuccessTimes(buySuccessTimes + 1)
				console.log("交易ID", tx)
			} catch (e) {
				try {
					await axios.request(ActiveBASE + '/fail-reason/add', {
						method: 'POST',
						data: {address: account.address, type: 2, reason: e.toString()}
					})
				} catch (e) {

				}
				toast(t("Fail"))
			} finally {
				setLoading(false)
			}
		} else {
			toast(t("Please connect wallet first"))
		}
	}, [buy])

	useEffect(() => {
		const amount = parseInt(price) * parseInt(count)
		setTotalAmount(amount / 1000000000000000000);
		if (allowance > amount) {
			setAllowanceSuccess(true)
		}
	}, [count, allowance]);

	useEffect(() => {
		if (price) {
			setNodePrice(price)
		}
		const amount = parseInt(price) * parseInt(count)
		setTotalAmount(amount / 1000000000000000000)
		if (allowance > amount) {
			setAllowanceSuccess(true)
		}
	}, [price, allowance])

	return (
		<div className="relative py-24 sm:py-32">
			<div className="px-6 mx-auto text-white max-w-7xl lg:px-8">
				<div className="flex flex-col items-center justify-center mx-auto sm:text-center">
					<h2 className="text-3xl font-bold tracking-tight sm:text-5xl">{t`Node`}</h2>
					<div className="flex flex-col items-center justify-center gap-12 mt-24 lg:gap-48 lg:flex-row">
						<img src="./img/nodes-fore-image.png" className="w-80 h-80 _sm:hidden"></img>
						<div className="flex flex-col gap-10 min-w-[250px]">
							<div className="flex flex-col gap-3 text-center lg:text-left">
								<p className="text-sm">{t`Price`}</p>
								<p className="text-2xl font-semibold">{formatUnits(nodePrice, 18)} USDT</p>
							</div>
							{/*<div className="flex flex-col gap-3 text-center lg:text-left">*/}
							{/*	<p className="text-sm">{t`Total Supply`}</p>*/}
							{/*	<p className="text-2xl font-semibold">{nodeInfo.stock}</p>*/}
							{/*</div>*/}
							<div className="flex flex-col gap-3 text-center lg:text-left">
								<p className="text-sm">{t`Intro`}</p>
								<p
									className="font-medium text-md">{t`Enabling secure and efficient data storage and decentralized applications.`}</p>
							</div>
						</div>
						<div
							className="bg-gradient-to-r from-[#111937] to-[#0F2835] rounded-xl p-6 text-left sm:min-w-[450px]">
							<p className="text-2xl font-medium lg:text-3xl">{t`Checkout`}</p>
							{/*<p className="mt-10 text-lg">{t(`Enter Your Referral Address`)}</p>*/}
							{/*<input type="text" onChange={(e) => {*/}
							{/*	setInviter(e.target.value)*/}
							{/*}} value={inviter}*/}
							{/*	   className="w-full p-3 mt-2 bg-transparent border border-white rounded-xl"></input>*/}
							<p className="mt-10 text-sm">{t`Price`}</p>
							<p className="mt-2 text-2xl">{formatUnits(nodePrice, 18)} USDT</p>
							<p className="mt-10">{t`Number`}</p>
							<input type="text" placeholder="Number" value={count}
								   onChange={(e) => setCount(e.target.value)}
								   className="w-full p-3 mt-2 bg-transparent border border-white rounded-xl"></input>
							<p className="mt-10">{t`Estimated Total Amount`}</p>
							<p className="mt-2 text-xl">{totalAmount} USDT</p>
							<div className="flex flex-row items-center justify-center gap-3 mt-10">
								<input type="checkbox"></input>
								<p>{t`I have read and agree that DATADAO are not investments.`}</p>
							</div>
							<div
								className="flex flex-row text-[#9ca3af] items-center text-xs leading-3 mt-[15px]">
								{!allowanceSuccess ?
									<div className='w-1/3 text-right'>{t`Approve Success`}</div>
									: <div className='w-1/3 text-right text-[#16a34a]'>{t`Approve Success`}</div>
								}
								<div className='w-1/3 h-px bg-[#9ca3af] mx-[5px]'></div>
								{!buySuccess ?
									<div className='w-1/3 text-left'>{t`BUY SUCCESS`}</div>
									: <div className='w-1/3 text-left text-[#16a34a]'>{t`BUY SUCCESS`}</div>}
							</div>
							<div className="flex justify-center w-full mt-[5px]">
								{allowanceSuccess ?
									<div
										className="bg-gradient-to-r from-[#1A61ED] to-[#11BAE3] py-3 px-20 rounded-xl mt-2 cursor-pointer text-center"
										onClick={handleBuy}>{loading ? <Spinner
										pulse/> : <> </>}{buySuccessTimes == 0 ? t`BUY NOW` : t`BUY AGAIN`}</div>
									:
									<div
										className="bg-gradient-to-r from-[#1A61ED] to-[#11BAE3] py-3 px-20 rounded-xl mt-2 cursor-pointer text-center"
										onClick={handleApprove}>{loading ? <Spinner pulse/> : <> </>}{t`Approve`}</div>
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
