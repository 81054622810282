import {bsc, bscTestnet} from "wagmi/chains";
import {memolayer} from "./memolayerChain";

/**
 * 测试环境V2
 **/
// export const ACTIVE_MEMO_ADDR = "0xdAf9922CB589ca06A883243417e461e171B0319A" // 激活MEMO地址
// export const BUY_ADDR = "0x96f954408B951a056a030022b49a8DeA93276772" // 购买合约
// export const USDT_ADDR = "0x5E2bD46522CD37011abb573Cb65130CEFCaE5C42" // USDT代币
// export const MEMO_ADDR = "0xeB8eec5a2dBf6e6f4Cc542ad31CCe706f8f80419" // MEMO代币
// export const DATAD_ADDR = "0xe33F4179b93F47e0DcdAf1A9B620a32891964143" // DATAD代币
// export const NODE_ADDR = "0xD0ED93eb0147499384DE48F5B6456e7d8152207d" // 节点合约
// export const ACTIVE_ADDR = "0xA6445a2C105839E2f3874BEED5b0f2f671F5A361" // 激活合约
// export const BUY_CHAIN_ID = bscTestnet.id // 购买节点
// export const NODE_CHAIN_ID = memolayer.id // 节点

/**
 * 正式环境
 **/
export const ACTIVE_MEMO_ADDR = "0xa653e14e8c6A46E80E719a46696eB75178a80292" // 正式
export const BUY_ADDR = "0x2EF456804461DD243f93B316a6E57d19D664f812" // 购买合约正式
export const USDT_ADDR = "0x55d398326f99059fF775485246999027B3197955" // USDT代币正式
export const MEMO_ADDR = "0xeB8eec5a2dBf6e6f4Cc542ad31CCe706f8f80419" // MEMO代币正式
export const DATAD_ADDR = "0xe33F4179b93F47e0DcdAf1A9B620a32891964143" // DATAD代币正式
export const NODE_ADDR = "0x674746D67b11354c045dd22713bEB2f02990E113" // 节点合约正式
export const ACTIVE_ADDR = "0x5c863DDe286eA89513e2a7DaEA47137e256c4cb5" // 激活合约正式
export const BUY_CHAIN_ID = bsc.id // 购买节点正式
export const NODE_CHAIN_ID = memolayer.id // 节点正式