import {useCallback, useState} from "react";
import {useTranslation} from "react-i18next";
import {useAccount} from "wagmi";
import {toast} from "react-toastify";
import axios from "axios";
import {ActiveBASE} from "../../utils/http";
import {useNavigate} from "react-router-dom";
import * as React from "react";


export default function Intro() {
	const {t} = useTranslation()
	const [inviter, setInviter] = useState(sessionStorage.getItem("inviter") ? sessionStorage.getItem("inviter") : "")
	const {address, isConnected} = useAccount()
	const navigate = useNavigate()

	// 购买
	const handleBind = useCallback(async () => {
		if (inviter == "") {
			toast(t("Enter Your Referral Address"))
			return
		}
		if (inviter == address) {
			toast(t("Inviter error"))
			return
		}
		if (isConnected) {
			try {
				const res = await axios.request(ActiveBASE + '/user/bind-inviter', {
					method: 'POST',
					data: {address: address, inviter: inviter}
				})
				if (res.status != 200) {
					return toast(t("Fail"))
				}
				if (res.data.code != 0) {
					return toast(t("Inviter error"))
				}
				toast(t("SUCCESS"))
				navigate("/")
			} catch (e) {
				toast(t("Fail"))
			}
		} else {
			toast(t("Please connect wallet first"))
		}
	}, [isConnected, inviter])

	const handleSetInviter = (e) => {
		setInviter(e.target.value)
	}

	return (
		<div className="relative py-24 sm:py-32">
			<div className="px-6 mx-auto text-white max-w-7xl lg:px-8">
				<div className="flex flex-col items-center justify-center mx-auto sm:text-center">
					<div className="w-full md:w-[500px]">
						<div className="text-left font-bold text-xl">
							{t`Bind Inviter`}
						</div>
						<input placeholder={t(`Enter Your Referral Address`)} type="text" onChange={handleSetInviter}
							   value={inviter}
							   className="w-full p-3 mt-2 bg-transparent border border-white rounded-xl"></input>
						<div
							className="bg-gradient-to-r from-[#1A61ED] to-[#11BAE3] py-3 px-20 rounded-xl mt-5 cursor-pointer text-center"
							onClick={handleBind}>{t`Bind`}</div>
					</div>
				</div>
			</div>
		</div>
	)
}
