 
import * as React from 'react'

class Intro extends React.Component{
	constructor(props){
	        super(props);
	}
 render () {
	 const info = this.props.data;
	 
    return (
<div className="relative py-24 sm:py-32">
        <div className="px-6 mx-auto text-white max-w-7xl lg:px-8">
          <div className="flex flex-col items-center justify-center mx-auto sm:text-center">
            <h2 className="text-3xl font-bold tracking-tight sm:text-5xl">{info.title}</h2>
            <div className="mt-10 text-lg leading-8 text-left bg-gradient-to-r from-[#0C2131] to-[#0C2530] rounded-md p-8">
               <div dangerouslySetInnerHTML={{ __html: info.content }}></div>
            </div>
          </div>
        </div>
      </div>
    );
}

}

export default Intro;

  