import http from '../utils/http';

/**
 * 获取首页列表
 */
function getArticleList() {
	return new Promise((resolve, reject) => {
		http("get", 'index/init').then(res => {
			resolve(res);
		}, error => {
			console.log("网络异常~", error);
			reject(error)
		})
	})
}

function getPolicy() {
	return new Promise((resolve, reject) => {
		http("get", 'index/policy').then(res => {
			resolve(res);
		}, error => {
			console.log("网络异常~", error);
			reject(error)
		})
	})
}

function getMission() {
	return new Promise((resolve, reject) => {
		http("get", 'index/delysium').then(res => {
			resolve(res);
		}, error => {
			console.log("网络异常~", error);
			reject(error)
		})
	})
}

function getNodeInfo() {
	return new Promise((resolve, reject) => {
		http("get", 'index/nodeinfo').then(res => {
			resolve(res);
		}, error => {
			console.log("网络异常~", error);
			reject(error)
		})
	})
}

function register(wallet_address, chain, code) {
	return new Promise((resolve, reject) => {
		http("post", 'dappuser/user/register', {
			wallet_address: wallet_address,
			'invite_code': code,
			'chain': chain
		}).then(res => {
			resolve(res);
		}, error => {
			console.log("网络异常~", error);
			reject(error)
		})
	})
}

function userInit() {
	return new Promise((resolve, reject) => {
		http("post", 'mi/user/init').then(res => {
			resolve(res);
		}, error => {
			console.log("网络异常~", error);
			reject(error)
		})
	})
}

function widthdraw(tokens, num) {
	return new Promise((resolve, reject) => {
		http("post", 'coin/assets/withdrawal', {coin_id: tokens, num: num}).then(res => {
			resolve(res);
		}, error => {
			console.log("网络异常~", error);
			reject(error)
		})
	})
}

function getMyNodeInfo() {
	return new Promise((resolve, reject) => {
		http("get", 'coin/assets/nodeinfo').then(res => {
			resolve(res);
		}, error => {
			console.log("网络异常~", error);
			reject(error)
		})
	})
}

function buyNode(goodsid, num, hash) {
	return new Promise((resolve, reject) => {
		http("post", 'mi/order/buy', {goods_id: goodsid, num: num, hash: hash}).then(res => {
			resolve(res);
		}, error => {
			console.log("网络异常~", error);
			reject(error)
		})
	})
}

function recharge(transactionHash) {
	return new Promise((resolve, reject) => {
		http("post", 'coin/index/recharge', {hash: transactionHash}).then(res => {
			resolve(res);
		}, error => {
			console.log("网络异常~", error);
			reject(error)
		})
	})
}


export {
	getArticleList,
	getMission,
	register,
	buyNode,
	getMyNodeInfo,
	widthdraw,
	recharge,
	userInit,
	getNodeInfo,
	getPolicy
}